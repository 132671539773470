<template>
  <div class="mobile-menu-spacer" />
</template>

<script>
export default {
  name: "MobileMenuSpacer",
};
</script>

<style lang="scss" scoped>
.mobile-menu-spacer {
  height: 30px;
  width: 100%;
  background: #f5f5f5;
}
</style>
