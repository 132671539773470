<template>
  <div class="mobile-app">
    <mobile-account-menu-item :profile="getProfile" />
    <mobile-menu-spacer />
    <mobile-menu :items="firstMenu" />
    <mobile-menu-spacer />
    <mobile-menu :items="secondMenu" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import MobileAccountMenuItem from "../components/MobileAccountMenuItem.vue";
import MobileMenuSpacer from "../components/MobileMenuSpacer.vue";
import MobileMenu from "../components/MobileMenu.vue";

export default {
  name: "MobileProfileView",
  components: {
    MobileAccountMenuItem,
    MobileMenuSpacer,
    MobileMenu,
  },
  data() {
    return {
      firstMenu: [
        {
          title: "Zmień hasło",
        },
        {
          title: "Wyloguj mnie",
          icon: "$logout",
          iconBackground: "#ffffff",
        },
        {
          title: "Zmień miejsce",
        },
      ],
      secondMenu: [
        {
          title: "Warunki korzystania z serwisu",
        },
        {
          title: "Polityka Prywatności",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getProfile: "mobileEditor/getProfile",
    }),
  },
};
</script>

<style lang="scss" scoped>
.mobile-app {
  width: 100%;
  height: 100%;
  background: white;
  overflow-y: scroll;
  overflow-x: hidden;
  background: white;
  padding-bottom: 1rem;
  scrollbar-color: #767676 #ffffff #767676;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 7px;
    background: rgba(#cacaca, 0.2);
  }

  &::-webkit-scrollbar-thumb {
    background: linear-gradient(180deg, #767676 0%, #ffffff 50%, #767676 100%);
    border-radius: 10px;
  }
}
</style>
