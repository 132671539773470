<template>
  <div class="mobile-menu">
    <template v-for="(item, index) in items">
      <mobile-menu-item
        :key="index"
        :title="item.title"
        :icon="item.icon"
        :icon-background="item.iconBackground"
      />
    </template>
  </div>
</template>

<script>
import MobileMenuItem from "./MobileMenuItem.vue";

export default {
  name: "MobileMenu",
  components: { MobileMenuItem },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped></style>
