<template>
  <div class="mobile-menu-item">
    <div class="mobile-menu-item__title">
      {{ title }}
    </div>
    <div class="mobile-menu-item__icon">
      <v-icon color="#1d1d1d" :style="{ background: iconBackground }">
        {{ icon }}
      </v-icon>
    </div>
  </div>
</template>

<script>
export default {
  name: "MobileMenuItem",
  props: {
    title: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "$chevron-right",
    },
    iconBackground: {
      type: String,
      default: "#f5f5f5",
    },
  },
};
</script>

<style lang="scss" scoped>
.mobile-menu-item {
  height: 55px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  font-size: 14px;
  border-bottom: solid 1px #dadada;
}

.mobile-menu-item__icon .v-icon {
  border-radius: 6px;
}
</style>
