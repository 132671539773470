<template>
  <div class="mobile-account-menu-item">
    <v-img :src="profile.avatar" class="mobile-account-menu-item__avatar" />
    <div class="mobile-account-menu-item__details">
      <span class="mobile-account-menu-item__details--full-name">{{
        profile.fullName
      }}</span>
      <span class="mobile-account-menu-item__details--mail">{{
        profile.email
      }}</span>
    </div>
    <div class="mobile-account-menu-item__action">
      <mobile-button
        :text="trans('edit')"
        background="#ffffff"
        color="#78c800"
      />
    </div>
  </div>
</template>

<script>
import mobilePreviewActions from "@/calendesk/mixins/mobilePreviewActions";
import MobileButton from "./MobileButton.vue";

export default {
  name: "MobileAccountMenuItem",
  components: { MobileButton },
  mixins: [mobilePreviewActions],
  props: {
    profile: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style lang="scss" scoped>
.mobile-account-menu-item {
  width: 100%;
  height: 75px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}

.mobile-account-menu-item__details--full-name {
  font-weight: 500;
  font-size: 14px;
}

.mobile-account-menu-item__details--mail {
  font-weight: 300;
  font-size: 12px;
}

.mobile-account-menu-item__avatar {
  max-height: 40px;
  max-width: 40px;
  border-radius: 50%;
}

.mobile-account-menu-item__details {
  width: 100%;
  font-size: 14px;
  padding-left: 0.5rem;
  display: flex;
  flex-direction: column;
}
</style>
